import { TreeViewComponent } from "@syncfusion/ej2-react-navigations";
import { Popover } from "antd";
import React, { useEffect, useRef, useState } from "react";
import NodeTemplate from "./NodeTemplate";
import { useTranslation } from "react-i18next";
const toJsonSchema = require("to-json-schema");

const PayloadMapper = ({ setForm, setPopup, form }) => {
  const { t } = useTranslation();
  const [json, setJson] = useState({ source: null, dest: null });
  const [jsonMapper, setJsonMapper] = useState({
    source: null,
    dest: [
      {
        id: "root",
        name: "root",
        type: "object",
        expanded: true,
        subChild: [],
      },
    ],
  });
  const [sourceJsonSchema, setSourceJsonSchema] = useState();
  const treeRef = useRef();
  const [sourceKey, setSourceKey] = useState(1);
  const [destinKey, setDestinKey] = useState(2);
  console.log(jsonMapper, "jsonMapper");
  const convertSchemaFormat = (properties, parentName, parentType) => {
    return Object.entries(properties).map(([name, property]) => {
      let convertedProperty = {
        id: name,
        name,
        type: property.type,
        expanded: true,
      };
      if (parentType === "array")
        convertedProperty = {
          ...convertedProperty,
          parentName,
          parentType,
        };
      if (property.type === "object" && property.properties) {
        convertedProperty.subChild = convertSchemaFormat(property.properties);
      } else if (property.type === "array" && property.items) {
        if (property.items.type === "object") {
          convertedProperty.subChild = convertSchemaFormat(
            property.items.properties,
            name,
            property.type
          );
        } else {
          convertedProperty = {
            ...convertedProperty,
            childType: property.items.type,
          };
        }
      }

      return convertedProperty;
    });
  };

  const onHandleChange = (e) => {
    setJson((pre) => ({ ...pre, [e.target.name]: e.target.value }));
  };

  const onJsonSave = (fieldName) => {
    try {
      const myObject = JSON.parse(json[fieldName]);
      const options = { arrays: { mode: "first" } };
      const schema = toJsonSchema(myObject, options);
      setSourceJsonSchema(schema);
      console.log(schema, "schema");
      if (schema.type === "object") {
        if (schema.properties) {
          const schemaArray = convertSchemaFormat(schema.properties);
          setJsonMapper((pre) => ({
            ...pre,
            [fieldName]: [
              {
                id: "root",
                name: "root",
                type: "object",
                expanded: true,
                subChild: schemaArray,
              },
            ],
          }));
        }
      } else if (schema.type === "array") {
        if (schema.items) {
          if (schema.items.type === "object") {
            const schemaArray = convertSchemaFormat(schema.items.properties);
            setJsonMapper((pre) => ({
              ...pre,
              [fieldName]: [
                {
                  id: "root",
                  name: "root",
                  type: "array",
                  expanded: true,
                  subChild: schemaArray,
                },
              ],
              dest: [
                {
                  id: "root",
                  name: "root",
                  type: "array",
                  expanded: true,
                  subChild: [],
                },
              ],
            }));
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateNodeTransformationType = (nodes, transformationType) => {
    return nodes.map((node) => {
      // Add a new node to the subChild array of the found parent node
      return {
        ...node,
        transformationType: "copy",
        sourceNode: {
          expanded: true,
          id: node.id,
          name: node.name,
          type: node.type,
        },
        subChild: node?.subChild
          ? updateNodeTransformationType(node.subChild)
          : [],
      };
    });
  };

  function updateNodeState(nodes, draggedNode, dropedNodeId) {
    const dragNode = getNodeById(jsonMapper.source, draggedNode.id);
    console.log(dragNode, "dnode");
    const modifiedNode = {
      ...dragNode,
      transformationType: dragNode.type === "array" ? "arrayMap" : "copy",
      subChild: dragNode?.subChild
        ? updateNodeTransformationType(dragNode.subChild)
        : [],
      sourceNode: dragNode,
    };
    return nodes.map((node) => {
      // Add a new node to the subChild array of the found parent node
      if (node.id === dropedNodeId) {
        return {
          ...node,
          type: node.type === "array" ? node.type : "object",
          subChild: node.subChild
            ? [...node.subChild, modifiedNode]
            : [modifiedNode],
        };
      } else if (node.subChild && node.subChild.length > 0) {
        // Recursively search for the parent node in subChild arrays
        return {
          ...node,
          subChild: updateNodeState(node.subChild, draggedNode, dropedNodeId),
        };
      }
      return node;
    });
  }

  const handleNodeDragStop = (args) => {
    const { draggedNodeData, droppedNodeData } = args;
    console.log(draggedNodeData, "dragnode");
    console.log(droppedNodeData, "dropnode");
    if (draggedNodeData && droppedNodeData) {
      setJsonMapper((prevSchema) => ({
        ...prevSchema,
        dest: updateNodeState(
          prevSchema.dest,
          draggedNodeData,
          droppedNodeData.id
        ),
      }));
      // setMappingConfig((prevConfig) => [...prevConfig, mappingConfigItem]);
      setDestinKey(new Date().getTime());
    }
  };

  const getNodeById = (nodes, nodeId) => {
    let foundNode = null;

    for (const node of nodes) {
      if (node.id === nodeId) {
        foundNode = node;
        break;
      } else if (node.subChild && node.subChild.length > 0) {
        foundNode = getNodeById(node.subChild, nodeId);
        if (foundNode) {
          break;
        }
      }
    }

    return foundNode;
  };

  const handleNodeEdit = (args) => {
    const { nodeData, newText } = args;
    // Set isEditing to true for the node being edited
    setJsonMapper((prevSchema) => ({
      ...prevSchema,
      dest: updateNodeEditState(prevSchema.dest, nodeData.id, newText),
    }));
    setDestinKey(new Date().getTime());
  };

  function updateNodeEditState(nodes, nodeId, newName) {
    return nodes.map((node) => {
      // Add a new node to the subChild array of the found parent node
      if (node.id === nodeId) {
        return {
          ...node,
          name: newName,
          subChild: node.subChild
            ? node.subChild.map((x) => ({ ...x, parentName: newName }))
            : [],
        };
      } else if (node.subChild && node.subChild.length > 0) {
        // Recursively search for the parent node in subChild arrays
        return {
          ...node,
          subChild: updateNodeEditState(node.subChild, nodeId, newName),
        };
      }
      return node;
    });
  }

  function updateNodeExpandState(nodes, nodeId, state) {
    return nodes.map((node) => {
      // Add a new node to the subChild array of the found parent node
      if (node.id === nodeId) {
        return {
          ...node,
          expanded: state,
        };
      } else if (node.subChild && node.subChild.length > 0) {
        // Recursively search for the parent node in subChild arrays
        return {
          ...node,
          subChild: updateNodeExpandState(node.subChild, nodeId, state),
        };
      }
      return node;
    });
  }

  function findPath(data, targetName, currentPath = []) {
    for (const item of data) {
      // Create a copy of the current path array to avoid modifying the original
      const newPath = [...currentPath, item.name];

      // Check if the current item's name matches the target name
      if (item.name === targetName) {
        // If matched, return the path as a string
        return newPath.join(".");
      }

      // If the current item has subChild, recursively search within it
      if (item.subChild) {
        const result = findPath(item.subChild, targetName, newPath);
        // If the result is not null, it means the target was found in the subChild
        if (result !== null) {
          return result;
        }
      }
    }

    // Return null if the target is not found in the current array
    return null;
  }

  const nodeExpanded = (e) => {
    setJsonMapper((prevSchema) => ({
      ...prevSchema,
      dest: updateNodeExpandState(prevSchema.dest, e.nodeData.id, true),
    }));
    setDestinKey(new Date().getTime());
  };

  const nodeCollapsed = (e) => {
    setJsonMapper((prevSchema) => ({
      ...prevSchema,
      dest: updateNodeExpandState(prevSchema.dest, e.nodeData.id, false),
    }));
    setDestinKey(new Date().getTime());
  };

  function generateMappingConfig(data) {
    const mappingConfigs = [];
    function processNode(node, parentPath) {
      const mappingConfigId = `mapping_config_${
        Math.floor(Math.random() * 100) + 1
      }`;
      if (node.type === "array") {
        const sourcePath = findPath(
          jsonMapper.source[0].subChild,
          node?.sourceNode?.name
        );
        const destinationPath = findPath(
          jsonMapper.dest[0].subChild,
          node.name
        );
        const transformationType = "arrayMap";
        const arrayMappings = [];
        if (node.subChild)
          node.subChild.forEach((el) => {
            const childmappingConfigId = `mapping_config_${
              Math.floor(Math.random() * 100) + 1
            }`;
            const sNode = getNodeById(jsonMapper.source, node.id);
            const dNode = getNodeById(jsonMapper.dest, el.id);
            const childSourcePath = findPath(sNode.subChild, dNode.id);
            const childDestinationPath = findPath(node.subChild, el.name);
            arrayMappings.push({
              destinationPath: childDestinationPath,
              value:
                el.transformationType === "copy"
                  ? childSourcePath
                  : el?.value || "",
              childmappingConfigId,
              transformationType: el.transformationType,
            });
            if (el.subChild) {
              el.subChild.forEach((elChildNode) => {
                processNode(elChildNode);
              });
            }
          });
        mappingConfigs.push({
          mappingConfigId,
          value: sourcePath,
          destinationPath,
          transformationType,
          arrayMappings,
        });
      } else {
        if (node.type !== "object") {
          const sourcePath = findPath(
            jsonMapper.source[0].subChild,
            node?.sourceNode?.name
          );
          const destinationPath = findPath(
            jsonMapper.dest[0].subChild,
            node.name
          );
          const transformationType = node.transformationType || "";

          mappingConfigs.push({
            mappingConfigId,
            value:
              transformationType === "copy" ? sourcePath : node?.value || "",
            destinationPath,
            transformationType,
          });
        }
        if (node.subChild) {
          node.subChild.forEach((childNode) => {
            processNode(childNode);
          });
        }
      }
    }

    data.forEach((drive) => {
      processNode(drive);
    });

    return mappingConfigs;
  }

  const onSave = () => {
    const configs = generateMappingConfig(jsonMapper.dest[0].subChild);
    console.log("path : ", configs);
    setForm((pre) => ({
      ...pre,
      payloadMapper: configs,
      sourceObj: sourceJsonSchema,
    }));
    setPopup(false);
  };
  function getSourceNode(source, value) {
    const keys = value.split(".");

    let currentNode = source;
    for (const key of keys) {
      if (currentNode.subChild) {
        currentNode = currentNode.subChild.find((child) => child.id === key);

        if (!currentNode) {
          return null;
        }
      } else {
        return null;
      }
    }

    return currentNode;
  }
  function generateOutput(payloadMapper, source) {
    let output = {
      expanded: true,
      id: "root",
      name: "root",
      type: "object",
      subChild: [],
    };
    payloadMapper.forEach((mapping) => {
      const destinationPath = mapping.destinationPath.split(".");
      let currentNode = output;

      for (let i = 0; i < destinationPath.length; i++) {
        const isLastNode = i === destinationPath.length - 1;
        const childId = destinationPath[i];
        const findNode = currentNode.subChild.find((x) => x.id === childId);
        const sourceNode = getSourceNode(source[0], mapping.value);
        if (!findNode) {
          const newNode = {
            expanded: true,
            id: childId,
            name: childId,
            sourceNode,
            transformationType: "copy",
            type:
              mapping.transformationType === "arrayMap"
                ? "array"
                : isLastNode
                ? "string"
                : "object",
            subChild: isLastNode ? [] : [],
          };

          currentNode.subChild.push(newNode);
          currentNode = newNode;
        } else {
          currentNode = findNode;
        }
      }

      if (mapping.transformationType === "arrayMap" && mapping.arrayMappings) {
        mapping.arrayMappings.forEach((arrayMapping) => {
          const arrayDestinationPath = arrayMapping.destinationPath.split(".");

          for (let i = 0; i < arrayDestinationPath.length; i++) {
            const isLastNode = i === arrayDestinationPath.length - 1;
            const childId = arrayDestinationPath[i];

            let existingNode = currentNode.subChild.find(
              (child) => child.id === childId
            );
            if (!existingNode) {
              const newNode = {
                expanded: true,
                id: childId,
                name: childId,
                type: isLastNode ? "string" : "object",
                subChild: isLastNode ? [] : [],
              };

              currentNode.subChild.push(newNode);
              currentNode = newNode;
            } else {
              currentNode = existingNode;
            }
          }
        });
      }
    });

    return output;
  }

  useEffect(() => {
    if (form.payloadMapper) {
      let source = [
        {
          id: "root",
          name: "root",
          type: "object",
          expanded: true,
          subChild: form.sourceObj?.properties
            ? convertSchemaFormat(form.sourceObj?.properties)
            : [],
        },
      ];
      let result = generateOutput(form.payloadMapper, source);
      setJsonMapper((pre) => ({
        ...pre,
        source: source,
        dest: [result],
      }));
      setSourceJsonSchema(form.sourceObj);
      setDestinKey(new Date().getTime());
      setSourceKey(new Date().getTime());
    }
  }, []);

  function nodeTemplates(data) {
    return (
      <div>
        {/* <img
        className="eimage"
        src={`https://ej2.syncfusion.com/demos/src/treeview/images/employees/${data.eimg}.png`}
        alt="${data.eimg}"
      /> */}
        <div className="ename">{data.name}</div>
        {/* <div className="ejob">{data.job}</div> */}
      </div>
    );
  }

  function nodeTemplate(data, templateType) {
    return (
      <NodeTemplate
        data={data}
        templateType={templateType}
        getNodeById={getNodeById}
        jsonMapper={jsonMapper}
        setJsonMapper={setJsonMapper}
        treeRef={treeRef}
        setKey={setSourceKey}
      />
    );
  }

  return (
    <div className="flex flex-col">
      <div className="text-sm w-full flex gap-2 font-medium bg-white rounded border-neutral-200 border p-5">
        <div className="flex flex-col gap-3 w-1/2 h-96">
          <div className="flex justify-between px-2">
            <label>{t("form_labels.source")}</label>
            {jsonMapper.source ? (
              <button
                onClick={() =>
                  setJsonMapper((pre) => ({ ...pre, source: null }))
                }
                className="px-1"
              >
                <img src="edit-icon.svg" className="h-4 w-4" />
              </button>
            ) : (
              <button
                onClick={() => onJsonSave("source")}
                className="border px-1 border-green-500 rounded"
              >
                <img src="save-icon.svg" className="h-3 w-4" />
              </button>
            )}
          </div>
          {!jsonMapper.source ? (
            <textarea
              onChange={onHandleChange}
              name="source"
              value={json?.source || ""}
              placeholder={t("form_labels.source_json_placeholder")}
              className="h-full p-3 focus:outline-none font-normal border-neutral-200 shadow-md border rounded-md w-full"
            />
          ) : (
            <div className="h-full pl-2 rounded-md py-4 overflow-auto bg-neutral-100 border shadow-md text-white">
              <TreeViewComponent
                fields={{
                  dataSource: jsonMapper.source,
                  id: "id",
                  text: "name",
                  child: "subChild",
                }}
                allowDragAndDrop
                allowMultiSelection={false}
                allowEditing={false}
                allowTextWrap
                key={sourceKey}
                // nodeDragStart={handleNodeDragStart}
                nodeTemplate={(props) => nodeTemplate(props, "source")}
              />
            </div>
          )}
        </div>
        <div className="flex flex-col gap-3 w-1/2  h-96">
          <div className="flex justify-between px-2">
            <label>{t("form_labels.destination")}</label>
          </div>
          <div className="h-full pl-2 rounded-md py-4 overflow-auto bg-neutral-100 border shadow-md text-white">
            <TreeViewComponent
              fields={{
                dataSource: jsonMapper.dest,
                id: "id",
                text: "name",
                child: "subChild",
                expanded: "expanded",
              }}
              allowEditing
              // style={{ height: "100%", overflowX: "hidden", overflowY: "auto" }}
              nodeDropped={handleNodeDragStop}
              cssClass="custom1"
              allowDragAndDrop
              nodeExpanded={nodeExpanded}
              nodeCollapsed={nodeCollapsed}
              key={destinKey}
              nodeEdited={handleNodeEdit}
              nodeTemplate={(props) => nodeTemplate(props, "dest")}
              ref={treeRef}
            />
          </div>
        </div>
      </div>
      <div className="flex gap-2 self-end mt-3">
        <button
          type="button"
          onClick={() => setPopup(false)}
          className="w-fit inline-flex justify-center rounded-md border border-transparent px-2 py-1 bg-indigo-500 font-medium text-white"
        >
          {t("buttons.close")}
        </button>
        <button
          type="button"
          onClick={() => onSave()}
          className="w-fit inline-flex justify-center rounded-md border border-transparent px-2 py-1 bg-green-500 font-medium text-white"
        >
          {t("buttons.save")}
        </button>
      </div>
    </div>
  );
};

export default PayloadMapper;
