import { Button, Checkbox, Form, Input, Modal, Popover, Select } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactJson from "react-json-view";
import {
  BtnBold,
  BtnItalic,
  Editor,
  EditorProvider,
  Toolbar,
  createButton,
} from "react-simple-wysiwyg";
import PayloadMapper from "./PayloadMapper";
import { useTranslation } from "react-i18next";
import {
  ApiOutlined,
  HomeOutlined,
  CarOutlined,
  NotificationOutlined,
  CloudDownloadOutlined,
  CloudUploadOutlined,
} from "@ant-design/icons";
import IntervalScheduler from "../IntervalScheduler";
const Notification = ({ setPopup, setSelectedEdge, setForm, form, onSave }) => {
  const { t } = useTranslation();
  const options = [
    { value: "text", label: t(`form_labels.text`) },
    { value: "html", label: "HTML" },
  ];
  const BtnAlignCenter = createButton("Align center", "≡", "justifyCenter");

  const handleChange = (value, option, targetName) => {
    if (targetName === "messageBodyType" && value === "text") {
      const formattedContent = removeHtmlTags(form.emailTemplate);
      setForm((pre) => ({
        ...pre,
        [targetName]: value,
        emailTemplate: formattedContent,
      }));
    } else {
      setForm((pre) => ({ ...pre, [targetName]: value }));
    }
  };

  const handleCheckBoxClick = (e) => {
    setForm((pre) => ({ ...pre, [e.target.name]: e.target.checked }));
  };
  function removeHtmlTags(text) {
    return text.replace(/<[^>]*>/g, "");
  }
  return (
    <div className="text-sm pt-2 flex flex-col justify-between font-medium bg-white rounded border-neutral-200 border my-5 h-3/4 shadow-sm">
      <div className=" h-full px-7 overflow-y-auto space-y-5 py-5">
        <div className="flex flex-col gap-2">
          <div className="flex gap-3 px-1 items-center justify-between">
            <div className="flex gap-3 items-center">
              <Checkbox
                value={form?.isEmails}
                checked={form?.isEmails}
                name="isEmails"
                onChange={handleCheckBoxClick}
              />
              <label>{t(`form_labels.emails`)}</label>
            </div>
            <div className="flex gap-2 items-center">
              <label>{t(`form_labels.type`)}</label>
              <Select
                className="h-5 text-xs w-24"
                value={form?.messageBodyType}
                onChange={(value, option) =>
                  handleChange(value, option, "messageBodyType")
                }
                options={options}
              />
            </div>
          </div>
          <Select
            allowClear
            mode="tags"
            open={false}
            suffixIcon={null}
            style={{ width: "100%" }}
            disabled={!form.isEmails}
            placeholder=""
            // className="min-h-16"
            value={form?.emails}
            onChange={(value, option) => handleChange(value, option, "emails")}
            options={[]}
          />
        </div>
        {form?.isEmails && (
          <div className="flex flex-col gap-2">
            <label className="px-1">
              {t(`form_labels.email_message_body`)}
            </label>
            <EditorProvider>
              <Editor
                containerProps={{ style: { resize: "vertical" } }}
                value={form?.emailTemplate}
                onChange={(e) =>
                  handleChange(e.target.value, "", "emailTemplate")
                }
              >
                {form.messageBodyType === "html" && (
                  <Toolbar>
                    <BtnBold />
                    <BtnItalic />
                    <BtnAlignCenter />
                  </Toolbar>
                )}
              </Editor>
            </EditorProvider>
          </div>
        )}
        <div className="flex flex-col gap-2">
          <div className="flex gap-3 px-1">
            <Checkbox
              value={form?.isWhatsApps}
              checked={form?.isWhatsApps}
              name="isWhatsApps"
              onChange={handleCheckBoxClick}
            />
            <label>{t(`form_labels.whatsapps`)}</label>
          </div>
          <Select
            allowClear
            mode="tags"
            open={false}
            suffixIcon={null}
            // className="min-h-16"
            disabled={!form.isWhatsApps}
            style={{ width: "100%" }}
            placeholder=""
            value={form?.whatsApps}
            onChange={(value, option) =>
              handleChange(value, option, "whatsApps")
            }
            options={[]}
          />
        </div>
        {form?.isWhatsApps && (
          <div className="flex flex-col gap-2">
            <label className="px-1">
              {t("form_labels.whatsapp_message_body")}
            </label>
            <EditorProvider>
              <Editor
                containerProps={{ style: { resize: "vertical" } }}
                value={form?.whatsAppTemplate}
                onChange={(e) =>
                  handleChange(e.target.value, "", "whatsAppTemplate")
                }
              />
            </EditorProvider>
          </div>
        )}
        <div className="flex flex-col gap-1">
          <div className="flex gap-3 px-1">
            <Checkbox
              value={form?.isPhoneNumbers}
              checked={form?.isPhoneNumbers}
              name="isPhoneNumbers"
              onChange={handleCheckBoxClick}
            />
            <label>{t(`form_labels.phone_numbers`)}</label>
          </div>
          <Select
            allowClear
            mode="tags"
            open={false}
            // className="min-h-16"
            suffixIcon={null}
            disabled={!form.isPhoneNumbers}
            style={{ width: "100%" }}
            placeholder=""
            value={form?.phoneNumbers}
            onChange={(value, option) =>
              handleChange(value, option, "phoneNumbers")
            }
            options={[]}
          />
        </div>
        {form?.isPhoneNumbers && (
          <div className="flex flex-col gap-1">
            <label className="px-1">{t("form_labels.sms_body")}</label>
            <EditorProvider>
              <Editor
                containerProps={{ style: { resize: "vertical" } }}
                value={form?.smsTemplate}
                onChange={(e) =>
                  handleChange(e.target.value, "", "smsTemplate")
                }
              />
            </EditorProvider>
          </div>
        )}
      </div>
      <div className="flex justify-end gap-1 mb-5 px-7">
        <button
          type="button"
          className="w-20 h-9 flex justify-center items-center bg-black rounded text-sm font-medium text-white"
          onClick={() => {
            setPopup(false);
            setSelectedEdge(null);
          }}
        >
          {t(`buttons.cancel`)}
        </button>
        <button
          type="button"
          className="w-20 h-9 flex justify-center items-center bg-green-600 rounded text-sm font-medium text-white"
          onClick={() => onSave()}
        >
          {t(`buttons.save`)}
        </button>
      </div>
    </div>
  );
};

const DeleteIcon = () => {
  return <img src="Delete-icon.svg" className="h-4 m-auto" />;
};

const APIs = ({
  setPopup,
  setSelectedEdge,
  onSave,
  form,
  setForm,
  selectedEdge,
}) => {
  const { t } = useTranslation();
  const [mapperPopup, setMapperPopup] = useState(false);
  const options = [
    {
      value: "get",
      label: (
        <span>
          <CloudDownloadOutlined /> GET
        </span>
      ),
    },
    {
      value: "post",
      label: (
        <span>
          <CloudUploadOutlined /> POST
        </span>
      ),
    },
  ];

  const handleSelectChange = (value, option, targetName) => {
    setForm((pre) => ({ ...pre, [targetName]: value }));
  };
  const handleVerbChange = (verb) => {
    setForm((prev) => ({ ...prev, httpVerb: verb }));
  };
  const handleChange = (e) => {
    setForm((pre) => ({ ...pre, [e.target.name]: e.target.value }));
  };

  const onHeaderChange = (e, i) => {
    setForm((pre) => ({
      ...pre,
      headers: pre.headers.map((x, index) =>
        index === i ? { ...x, [e.target.name]: e.target.value } : x
      ),
    }));
  };
  return (
    <div className="text-sm flex flex-col justify-between relative font-medium bg-white rounded border-neutral-200 border px-7 gap-2 my-5 h-3/4 shadow-sm">
      {selectedEdge.source !== "source" && (
        <Modal
          title="PayLoad Mapper"
          centered
          style={{ width: "max-content", paddingBottom: "0px" }}
          open={mapperPopup}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          closable
          onCancel={() => setMapperPopup(false)}
          width={800}
        >
          <PayloadMapper
            setForm={setForm}
            setPopup={setMapperPopup}
            form={form}
          />
        </Modal>
      )}
      <div className="flex flex-col gap-1">
        {selectedEdge.source !== "source" && (
          <>
            {" "}
            <div className="flex justify-between border border-neutral-200 rounded-md p-3 text-sm font-medium mt-5">
              <label className="">{t(`form_labels.payload_mapper`)}</label>
              <button
                onClick={() => setMapperPopup(true)}
                className="text-blue-600"
              >
                {t(`buttons.edit`)}
              </button>
            </div>
            <div className="flex flex-col text-sm font-medium gap-1 mt-5">
              <label className="">{t(`form_labels.http_verb`)}</label>
              <div className="flex gap-2">
                {options.map((option) => (
                  <Button
                    key={option.value}
                    className={`
                ${form.httpVerb === option.value && "bg-blue-500 text-white"}
                ${form.httpVerb !== option.value && "bg-white text-black"}
                `}
                    onClick={() => handleVerbChange(option.value)}
                  >
                    {option.label}
                  </Button>
                ))}
              </div>
            </div>
          </>
        )}
        {selectedEdge.source === "source" && (
          <IntervalScheduler integration={form} setIntegration={setForm} />
        )}
        <div className="flex flex-col text-sm font-medium gap-1 mt-5">
          <label className="">{t(`form_labels.api_url`)}</label>
          <Input
            style={{ width: "100%" }}
            placeholder={t(`form_labels.api_url_placeholder`)}
            name="apiUrl"
            value={form?.apiUrl}
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col gap-1 mt-5">
          <div className="w-full text-sm flex justify-between gap-4">
            <label className="text-sm font-medium">
              {t(`form_labels.headers`)}
            </label>
            <button
              onClick={() =>
                setForm((pre) => ({
                  ...pre,
                  headers: pre.headers
                    ? [...pre.headers, { key: "", value: "" }]
                    : [{ key: "", value: "" }],
                }))
              }
              className="bg-green-300 border border-green-600 rounded h-6 w-6 flex justify-center items-center text-xl text-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-4 h-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
            </button>
          </div>
          <div className="flex flex-col gap-2 border py-1 border-slate-200 px-1 rounded overflow-auto relative h-40">
            <div className="w-full text-xs flex gap-2 text-white sticky top-0 z-50 rounded">
              <div className="w-2/5 text-center bg-neutral-600 py-1 rounded-tl rounded-bl">
                {t(`form_labels.key`)}
              </div>
              <div className="w-1/2 text-center bg-neutral-600 py-1">
                {t(`form_labels.value`)}
              </div>
              <div className="w-[10%] px-1 text-center bg-neutral-600 py-1 rounded-tr rounded-br">
                {t(`form_labels.action`)}
              </div>
            </div>
            {form?.headers?.map((x, i) => (
              <div key={i} className="w-full flex text-xs gap-2">
                <Input
                  placeholder="key"
                  className="w-2/5"
                  value={x?.key}
                  name="key"
                  onChange={(e) => onHeaderChange(e, i)}
                />
                <Input
                  className="w-1/2"
                  placeholder="value"
                  value={x?.value}
                  name="value"
                  onChange={(e) => onHeaderChange(e, i)}
                />
                <Button
                  classNames=""
                  value="delete"
                  icon={<DeleteIcon />}
                  className="w-[10%]"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    setForm((pre) => ({
                      ...pre,
                      headers: pre.headers.filter((_, index) => index !== i),
                    }))
                  }
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex justify-end gap-1 mb-5">
        <button
          type="button"
          className="w-20 h-9 flex justify-center items-center bg-black rounded text-sm font-medium text-white"
          onClick={() => {
            setPopup(false);
            setSelectedEdge(null);
          }}
        >
          {t(`buttons.cancel`)}
        </button>
        <button
          type="button"
          className="w-20 h-9 flex justify-center items-center bg-green-600 rounded text-sm font-medium text-white"
          onClick={() => onSave()}
        >
          {t(`buttons.save`)}
        </button>
      </div>
    </div>
  );
};

export const EdgeForm = (props) => {
  const { t } = useTranslation();
  const defaultConnectivityForm = {
    isEmails: false,
    isWhatsApps: false,
    isPhoneNumbers: false,
    emails: [],
    whatsApps: [],
    phoneNumbers: [],
    emailTemplate: "",
    payloadMapper: [],
    messageBodyType: "text",
  };
  const defaultAPIForm = {
    httpVerb: "get",
    apiUrl: "",
    headers: [{ key: "", value: "" }],
    interval: {
      days: 0,
      hours: 1,
      minutes: 0,
    },
  };
  const { setPopup, setNodes, setSelectedEdge, selectedEdge, nodes } = props;
  const options = [
    {
      ableToPull: false,
      value: "notification",
      label: (
        <span className="flex gap-2">
          <NotificationOutlined />
          {t("form_labels.notification")}
        </span>
      ),
    },
    {
      ableToPull: true,
      value: "api",
      label: (
        <span className="flex gap-2">
          <ApiOutlined />
          API
        </span>
      ),
    },
    {
      ableToPull: true,
      value: "sap",
      label: (
        <span className="flex gap-2">
          <img className="max-w-10 h-6" src="sap.svg" alt="logo" />
          SAP
        </span>
      ),
    },
    {
      ableToPull: true,
      value: "oracle",
      label: (
        <span className="flex gap-2">
          <img className="max-w-10 h-6" src="oracle.jpg" alt="logo" />
          Oracle
        </span>
      ),
    },
    {
      ableToPull: true,
      value: "azure",
      label: (
        <span className="flex gap-2">
          <img className="max-w-10 h-6" src="azure.svg" alt="logo" />
          Azure
        </span>
      ),
    },
    {
      ableToPull: true,
      value: "servicenow",
      label: (
        <span className="flex gap-2">
          <img className="max-w-10 h-6" src="servicenow.svg" alt="logo" />
          Servicenow
        </span>
      ),
    },
    {
      ableToPull: true,
      value: "ibm",
      label: (
        <span className="flex gap-2">
          <img className="max-w-10 h-6" src="ibm.png" alt="logo" />
          IBM
        </span>
      ),
    },
    {
      ableToPull: true,
      value: "ifs",
      label: (
        <span className="flex gap-2">
          <img className="max-w-10 h-6" src="ifs.png" alt="logo" />
          IFS
        </span>
      ),
    },
    {
      ableToPull: true,
      value: "dynamics365",
      label: (
        <span className="flex gap-2">
          <img className="max-w-10 h-6" src="dynamics365.png" alt="logo" />
          Dynamics365
        </span>
      ),
    },
    {
      ableToPull: true,
      value: "infor",
      label: (
        <span className="flex gap-2">
          <img className="max-w-10 h-6" src="infor.png" alt="logo" />
          Infor
        </span>
      ),
    },
  ];
  const modeOptions = [
    {
      value: "pull",
      label: (
        <span>
          <CloudDownloadOutlined /> PULL
        </span>
      ),
    },
    {
      value: "push",
      label: (
        <span>
          <CloudUploadOutlined /> PUSH
        </span>
      ),
    },
  ];

  const [selectedType, setSelectedType] = useState("push");
  const [selectedMode, setSelectedMode] = useState(options[1]);
  const [form, setForm] = useState(defaultConnectivityForm);
  const [destNode, setDestNode] = useState();

  const handleChange = (value, option) => {
    setSelectedMode(option);
    if (value === "notification") {
      setForm(defaultConnectivityForm);
    } else setForm(defaultAPIForm);
  };

  const onSave = () => {
    let selectedNodeId =
      selectedEdge.source === "source"
        ? selectedEdge.source
        : selectedEdge.target;
    setNodes((pre) =>
      pre.map((x) =>
        x.id === selectedNodeId
          ? {
              ...x,
              data: {
                ...x.data,
                connectivity: {
                  mode: selectedEdge.source !== "source" ? null : selectedType,
                  type: selectedMode.value,
                  configurations: JSON.stringify(form),
                },
              },
            }
          : x
      )
    );
    setPopup(false);
  };
  const handleModeChange = (value) => {
    setSelectedType(value);
  };
  useEffect(() => {
    let selectedNodeId =
      selectedEdge.source === "source"
        ? selectedEdge.source
        : selectedEdge.target;
    const node = nodes.find((x) => x.id === selectedNodeId);

    setDestNode(destNode);
    if (node.data.connectivity) {
      if (selectedEdge.source === "source") {
        setSelectedType(node.data.connectivity.mode);
      }
      setSelectedMode(
        options.find((x) => x.value === node.data.connectivity.type)
      );
      console.log(node.data.connectivity, "conectivify");
      //   if (node.data.connectivity.type === "notification") {
      setForm(JSON.parse(node.data.connectivity.configurations));
      //   } else setForm(defaultAPIForm);
    } else {
      setForm(defaultConnectivityForm);
    }
  }, []);

  const getHeader = () => {
    let destNode;
    if (selectedEdge.source === "source") {
      destNode = nodes.find((x) => x.id === selectedEdge.source);
    } else {
      destNode = nodes.find((x) => x.id === selectedEdge.target);
    }
    return selectedEdge.source + "-" + destNode.data.app.name;
  };

  return (
    <div className="overflow-y-auto transition-all ease-in-out duration-200 bg-slate-50 border-l shadow-lg shadow-blue-400 p-5 right-5 h-full w-2/3">
      <div className="flex justify-between">
        <div className="font-semibold text-lg">{getHeader()}</div>
        <button type="button" onClick={() => setPopup(false)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div className="flex gap-2 mt-3">
        {selectedEdge.source === "source" &&
          modeOptions.map((option) => (
            <Button
              key={option.value}
              className={`
                ${selectedType === option.value && "bg-blue-500 text-white"}
                ${selectedType !== option.value && "bg-white text-black"}
                `}
              onClick={() => handleModeChange(option.value)}
            >
              {option.label}
            </Button>
          ))}
      </div>
      {(selectedType !== "push" || selectedEdge.source !== "source") && (
        <>
          <div className="flex flex-col text-lg font-semibold gap-1 mt-5">
            <label className="">{t(`form_labels.mode`)}</label>
            <Select
              allowClear
              style={{ width: "100%" }}
              placeholder="Please select"
              value={selectedMode?.value}
              onChange={handleChange}
              options={
                selectedType === "pull"
                  ? options.filter((i) => i.ableToPull)
                  : options
              }
            />
          </div>
          {selectedMode.value === "notification" ? (
            <Notification
              {...props}
              setForm={setForm}
              form={form}
              onSave={onSave}
            />
          ) : (
            <APIs {...props} setForm={setForm} form={form} onSave={onSave} />
          )}
        </>
      )}
    </div>
  );
};
