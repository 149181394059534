import React, { useContext, useEffect, useState } from "react";
import { Steps, Select, Button, Card, Input, Modal } from "antd";
import { EdgeForm } from "./IntegrationFlow/EdgeForms/EdgeForm";
import {
  ApiOutlined,
  DatabaseOutlined,
  EditOutlined,
  NotificationOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { BASE_URL } from "../../constants";
import axios from "axios";
import { toast } from "react-toastify";
import SourceForm from "./SourceForm";
import DestinationForm from "./DestinationForm";
import AppContext from "../../AppContext";
import SqlDbConnector from "./SqlDbConnector";

const { Step } = Steps;
const { Option } = Select;

const IntegrationWizard = () => {
  const { t } = useTranslation();
  const { authCtx } = useContext(AppContext);
  const applications = [
    {
      ableToPull: false,
      value: "notification",
      label: (
        <span className="flex gap-2">
          <NotificationOutlined />
          {t("form_labels.notification")}
        </span>
      ),
    },
    {
      ableToPull: false,
      value: "api",
      label: (
        <span className="flex gap-2">
          <ApiOutlined />
          API
        </span>
      ),
    },
    {
      ableToPull: true,
      value: "sap",
      label: (
        <span className="flex gap-2">
          <img className="max-w-10 h-6" src="sap.svg" alt="logo" />
          SAP
        </span>
      ),
    },
    {
      ableToPull: true,
      value: "oracle",
      label: (
        <span className="flex gap-2">
          <img className="max-w-10 h-6" src="oracle.jpg" alt="logo" />
          Oracle
        </span>
      ),
    },
    {
      ableToPull: true,
      value: "azure",
      label: (
        <span className="flex gap-2">
          <img className="max-w-10 h-6" src="azure.svg" alt="logo" />
          Azure
        </span>
      ),
    },
    {
      ableToPull: true,
      value: "servicenow",
      label: (
        <span className="flex gap-2">
          <img className="max-w-10 h-6" src="servicenow.svg" alt="logo" />
          Servicenow
        </span>
      ),
    },
    {
      ableToPull: true,
      value: "ibm",
      label: (
        <span className="flex gap-2">
          <img className="max-w-10 h-6" src="ibm.png" alt="logo" />
          IBM
        </span>
      ),
    },
    {
      ableToPull: true,
      value: "ifs",
      label: (
        <span className="flex gap-2">
          <img className="max-w-10 h-6" src="ifs.png" alt="logo" />
          IFS
        </span>
      ),
    },
    {
      ableToPull: true,
      value: "dynamics365",
      label: (
        <span className="flex gap-2">
          <img className="max-w-10 h-6" src="dynamics365.png" alt="logo" />
          Dynamics365
        </span>
      ),
    },
    {
      ableToPull: true,
      value: "infor",
      label: (
        <span className="flex gap-2">
          <img className="max-w-10 h-6" src="infor.png" alt="logo" />
          Infor
        </span>
      ),
    },

    {
      ableToPull: true,
      value: "application",
      label: (
        <span className="flex gap-2">
          <ApiOutlined />
          Custom Application
        </span>
      ),
    },
  ];
  const [currentStep, setCurrentStep] = useState(0);
  const [showModal, setShowModal] = useState(0);
  const [isFormValid, setIsFormValid] = useState(false);
  const [sourceConfiguration, setSourceConfiguration] = useState({
    httpVerb: "get", // Default httpVerb
    apiUrl: "", // Initial empty apiUrl
    headers: [], // Initial empty array for headers
    interval: {
      days: 0,
      hours: 1,
      minutes: 0,
    },
  });
  const [integration, setIntegration] = useState({
    id: "new",
    status: true,
    destinations: [],
    position: { x: 500, y: 500 },
    source: {
      id: "new",
      connectivity: { mode: "push" },
      position: { x: 76.79774, y: 236.81102 },
    },
  });
  const [editingDestination, setEditingDestination] = useState(null);
  const next = () => setCurrentStep(currentStep + 1);
  const prev = () => setCurrentStep(currentStep - 1);
  const onSave = async () => {
    try {
      let res = await axios.post(
        `${BASE_URL}/api/IntegrationItems/IntegrationWizard`,
        integration
      );
      if (res.status === 200 || res.status === 201 || res.status === 204) {
        toast.success("Node created successfully!");
        window.location.href = `#/integration/${res.data.id}`;
        window.location.reload();
      }
    } catch (err) {
      toast.error("An error occured while creating catalog!");
    }
  };
  useEffect(() => {
    console.log(authCtx.profile, "profile");
    setIntegration((prv) => ({
      ...prv,
      companyCode: authCtx.profile.companyCode,
      companyId: authCtx.profile.companyId,
      company: authCtx.profile.company,
      createdUserId: authCtx.profile.id,
    }));
  }, []);
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleChange = (fieldName, value) => {
    setIntegration({
      ...integration,
      [fieldName]: value,
    });
    checkFormValidity();
  };
  const addDestination = () => {
    setIntegration((prevIntegration) => ({
      ...prevIntegration,
      destinations: [
        ...prevIntegration.destinations,
        {
          id: Date.now().toString(),
          name: "",
          position: { x: 1106.9828, y: 226.08763 },
          configData: {
            httpVerb: "get",
            apiUrl: "",
            headers: [],
          },
          connectivity: {
            mode: "pull",
            type: "",
            configurations: null,
          },
        },
      ],
    }));
    setEditingDestination(integration?.destinations.length);
  };

  const handleEditDestination = (i) => {
    setEditingDestination(i);
  };
  const checkFormValidity = () => {
    switch (currentStep) {
      case 0:
        setIsFormValid(integration.name && integration.description);
        break;
      case 1:
        setIsFormValid(
          integration.source.connectivity.type &&
            integration.source.connectivity.mode &&
            integration.source.name &&
            (integration.source.connectivity.mode === "push" ||
              (sourceConfiguration.apiUrl && sourceConfiguration.httpVerb))
        );
        break;
      case 2:
        setIsFormValid(
          integration.destinations.length > 0 &&
            integration.destinations.every(
              (dest) =>
                dest.connectivity.type &&
                dest.name &&
                dest.configData.httpVerb &&
                dest.configData.apiUrl
            )
        );
        break;
      default:
        setIsFormValid(true);
    }
  };

  const steps = [
    {
      title: "General Info",
      content: (
        <form className="space-y-4">
          <div>
            <label className="block text-gray-700">Integration Name</label>
            <Input
              type="text"
              value={integration.name}
              onChange={(e) => handleChange("name", e.target.value)}
              placeholder="Enter integration name"
              className="mt-1 block w-full"
            />
          </div>
          <div>
            <label className="block text-gray-700">Description</label>
            <Input.TextArea
              value={integration.description}
              onChange={(e) => handleChange("description", e.target.value)}
              placeholder="Enter description"
              className="mt-1 block w-full border border-gray-300 rounded-md p-2"
            />
          </div>
        </form>
      ),
    },
    {
      title: "Choose Source",
      content: (
        <SourceForm
          config={sourceConfiguration}
          setConfig={setSourceConfiguration}
          integration={integration}
          setIntegration={setIntegration}
          applications={applications}
          checkFormValidity={checkFormValidity}
        />
      ),
    },
    {
      title: "Choose Destination",
      content: (
        <div className="space-y-4">
          {integration.destinations.map((destination, index) => (
            <div className="bg-slate-50 p-4  rounded-md" key={index}>
              {editingDestination === index ? (
                <DestinationForm
                  destination={destination}
                  handleEditDestination={handleEditDestination}
                  index={index}
                  config={destination.configData}
                  setConfig={setSourceConfiguration}
                  integration={integration}
                  setIntegration={setIntegration}
                  applications={applications}
                />
              ) : (
                <div className="space-y-4">
                  <EditOutlined
                    onClick={() => {
                      handleEditDestination(index);
                    }}
                  />
                  <div>
                    <label className="block text-gray-700">Destination</label>
                    <Select
                      value={destination?.connectivity?.type}
                      disabled
                      placeholder="Select Destination"
                      className="w-full mt-1"
                    >
                      <Option value="application">Application</Option>
                      <Option value="sqlDb">SQLDB Connector</Option>
                      <Option value="ftp">FTP</Option>
                      <Option value="notification">Notification</Option>
                    </Select>
                  </div>
                  {destination?.connectivity?.type === "application" && (
                    <>
                      <div className="flex flex-col text-lg font-semibold gap-1 mt-5">
                        <label className="">{t(`form_labels.mode`)}</label>
                        <Select
                          allowClear
                          style={{ width: "100%" }}
                          placeholder="Please select"
                          value={destination?.connectivity?.type}
                          disabled
                        />
                      </div>
                      {destination?.connectivity?.type === "application" && (
                        <div>
                          <label className="block text-gray-700">
                            Application Name
                          </label>
                          <input
                            type="text"
                            disabled
                            value={destination?.name}
                            onChange={(e) =>
                              handleChange("name", e.target.value)
                            }
                            placeholder="Enter application name"
                            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          ))}
          <Button type="dashed" onClick={addDestination} className="w-full">
            + Add More Destination
          </Button>
        </div>
      ),
    },
    {
      title: "Summary",
      content: (
        <div className="">
          <div className="space-y-4">
            <Card title="General Info">
              <p>
                <strong>Name:</strong> {integration.name}
              </p>
              <p>
                <strong>Description:</strong> {integration.description}
              </p>
            </Card>
            <Card title="Source">
              <p>
                <strong>Type:</strong> {integration.source.connectivity.type}
              </p>
              <p>
                <strong>Mode:</strong> {integration.source.connectivity.mode}
              </p>
              <p>
                <strong>App Name:</strong> {integration.source.name}
              </p>
              {integration.source.connectivity.mode === "pull" ? (
                <>
                  <p>
                    <strong>API URL:</strong> {sourceConfiguration.apiUrl}
                  </p>
                  <p>
                    <strong>HTTP Verb:</strong> {sourceConfiguration.httpVerb}
                  </p>
                </>
              ) : (
                <>
                  <p>
                    <strong>App Mode:</strong>{" "}
                    {integration.source.connectivity.type}
                  </p>
                </>
              )}
            </Card>
            <Card title="Destinations">
              <div className="mb-4 grid space-x-2 grid-cols-3">
                {integration.destinations.map((destination, index) => (
                  <Card title={`Destination ${index + 1}`} key={index}>
                    <p>
                      <strong>Type:</strong> {destination.connectivity.type}
                    </p>
                    <p>
                      <strong>Name:</strong> {destination.name}
                    </p>
                    <p>
                      <strong>HTTP Verb:</strong>{" "}
                      {destination.configData.httpVerb.toUpperCase()}
                    </p>
                  </Card>
                ))}
              </div>
            </Card>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="p-6 flex flex-col items-center">
      <div className="flex gap-4 mb-6 w-full max-w-7xl">
        <h2 className="md:text-xl font-semibold text-gray-900">
          Create new Integration
        </h2>
        <Button onClick={() => setShowModal(true)} type="default">
          Help?
        </Button>
        {showModal && (
          <div
            id="container"
            onClick={handleCloseModal}
            className="z-[99999] fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center"
          >
            {/* <div className="rounded"> */}
            <iframe
              title="tutorial"
              className="md:w-1/2 w-4/5 md:h-3/5 h-2/5"
              src={`https://www.youtube.com/embed/f9reXwnZ0z0?si=YcfHt9Gg2GFSD3sU`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            {/* </div> */}
          </div>
        )}
      </div>
      <Steps className="max-w-7xl" current={currentStep}>
        {steps.map((item, index) => (
          <Step key={index} title={item.title} />
        ))}
      </Steps>
      <div className="mt-4 py-4 w-full rounded shadow-sm overflow-y-auto max-w-7xl max-h-[65vh]">
        {steps[currentStep].content}
      </div>
      <div className="flex w-full justify-between mt-4 max-w-7xl">
        {currentStep > 0 && <Button onClick={prev}>Previous</Button>}
        {currentStep < steps.length - 1 && (
          <Button type="primary" onClick={next} disabled={!isFormValid}>
            Next
          </Button>
        )}
        {currentStep === steps.length - 1 && (
          <Button type="primary" onClick={onSave}>
            Submit
          </Button>
        )}
      </div>
    </div>
  );
};

export default IntegrationWizard;
