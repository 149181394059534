import { Checkbox, Select } from "antd";
import React, { useState } from "react";
import {
  BtnBold,
  BtnItalic,
  Editor,
  EditorProvider,
  Toolbar,
  createButton,
} from "react-simple-wysiwyg";
import { useTranslation } from "react-i18next";
export const NotificationConnector = ({ setIntegration, index }) => {
  const { t } = useTranslation();
  const [form, setForm] = useState({
    isEmails: false,
    isWhatsApps: false,
    isPhoneNumbers: false,
    emails: [],
    whatsApps: [],
    phoneNumbers: [],
    emailTemplate: "",
    payLoadMapper: [],
    messageBodyType: "text",
  });
  const options = [
    { value: "text", label: t(`form_labels.text`) },
    { value: "html", label: "HTML" },
  ];
  const BtnAlignCenter = createButton("Align center", "≡", "justifyCenter");
  const handleSave = () => {
    setIntegration((integration) => ({
      ...integration,
      destinations: integration.destinations.map((dest, i) =>
        i === index
          ? {
              ...dest,
              connectivity: {
                ...dest?.connectivity,
                configurations: JSON.stringify(form),
              },
            }
          : dest
      ),
    }));
  };
  const handleChange = (value, option, targetName) => {
    if (targetName === "messageBodyType" && value === "text") {
      const formattedContent = removeHtmlTags(form.emailTemplate);
      setForm((pre) => ({
        ...pre,
        [targetName]: value,
        emailTemplate: formattedContent,
      }));
    } else {
      setForm((pre) => ({ ...pre, [targetName]: value }));
    }
  };

  const handleCheckBoxClick = (e) => {
    setForm((pre) => ({ ...pre, [e.target.name]: e.target.checked }));
  };
  function removeHtmlTags(text) {
    return text.replace(/<[^>]*>/g, "");
  }
  return (
    <div
      onMouseLeave={handleSave}
      className="text-sm pt-2 flex flex-col justify-between font-medium bg-white rounded border-neutral-200 border my-5 h-3/4 shadow-sm"
    >
      <div className=" h-full px-7 overflow-y-auto space-y-5 py-5">
        <div className="flex flex-col gap-2">
          <div className="flex gap-3 px-1 items-center justify-between">
            <div className="flex gap-3 items-center">
              <Checkbox
                value={form?.isEmails}
                checked={form?.isEmails}
                name="isEmails"
                onChange={handleCheckBoxClick}
              />
              <label>{t(`form_labels.emails`)}</label>
            </div>
            <div className="flex gap-2 items-center">
              <label>{t(`form_labels.type`)}</label>
              <Select
                className="h-5 text-xs w-24"
                value={form?.messageBodyType}
                onChange={(value, option) =>
                  handleChange(value, option, "messageBodyType")
                }
                options={options}
              />
            </div>
          </div>
          <Select
            allowClear
            mode="tags"
            open={false}
            suffixIcon={null}
            style={{ width: "100%" }}
            disabled={!form.isEmails}
            placeholder=""
            // className="min-h-16"
            value={form?.emails}
            onChange={(value, option) => handleChange(value, option, "emails")}
            options={[]}
          />
        </div>
        {form?.isEmails && (
          <div className="flex flex-col gap-2">
            <label className="px-1">
              {t(`form_labels.email_message_body`)}
            </label>
            <EditorProvider>
              <Editor
                containerProps={{ style: { resize: "vertical" } }}
                value={form?.emailTemplate}
                onChange={(e) =>
                  handleChange(e.target.value, "", "emailTemplate")
                }
              >
                {form.messageBodyType === "html" && (
                  <Toolbar>
                    <BtnBold />
                    <BtnItalic />
                    <BtnAlignCenter />
                  </Toolbar>
                )}
              </Editor>
            </EditorProvider>
          </div>
        )}
        <div className="flex flex-col gap-2">
          <div className="flex gap-3 px-1">
            <Checkbox
              value={form?.isWhatsApps}
              checked={form?.isWhatsApps}
              name="isWhatsApps"
              onChange={handleCheckBoxClick}
            />
            <label>{t(`form_labels.whatsapps`)}</label>
          </div>
          <Select
            allowClear
            mode="tags"
            open={false}
            suffixIcon={null}
            // className="min-h-16"
            disabled={!form.isWhatsApps}
            style={{ width: "100%" }}
            placeholder=""
            value={form?.whatsApps}
            onChange={(value, option) =>
              handleChange(value, option, "whatsApps")
            }
            options={[]}
          />
        </div>
        {form?.isWhatsApps && (
          <div className="flex flex-col gap-2">
            <label className="px-1">
              {t("form_labels.whatsapp_message_body")}
            </label>
            <EditorProvider>
              <Editor
                containerProps={{ style: { resize: "vertical" } }}
                value={form?.whatsAppTemplate}
                onChange={(e) =>
                  handleChange(e.target.value, "", "whatsAppTemplate")
                }
              />
            </EditorProvider>
          </div>
        )}
        <div className="flex flex-col gap-1">
          <div className="flex gap-3 px-1">
            <Checkbox
              value={form?.isPhoneNumbers}
              checked={form?.isPhoneNumbers}
              name="isPhoneNumbers"
              onChange={handleCheckBoxClick}
            />
            <label>{t(`form_labels.phone_numbers`)}</label>
          </div>
          <Select
            allowClear
            mode="tags"
            open={false}
            // className="min-h-16"
            suffixIcon={null}
            disabled={!form.isPhoneNumbers}
            style={{ width: "100%" }}
            placeholder=""
            value={form?.phoneNumbers}
            onChange={(value, option) =>
              handleChange(value, option, "phoneNumbers")
            }
            options={[]}
          />
        </div>
        {form?.isPhoneNumbers && (
          <div className="flex flex-col gap-1">
            <label className="px-1">{t("form_labels.sms_body")}</label>
            <EditorProvider>
              <Editor
                containerProps={{ style: { resize: "vertical" } }}
                value={form?.smsTemplate}
                onChange={(e) =>
                  handleChange(e.target.value, "", "smsTemplate")
                }
              />
            </EditorProvider>
          </div>
        )}
      </div>
    </div>
  );
};
