import React from "react";
import { Select } from "antd";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const IntervalScheduler = ({ integration, setIntegration }) => {
  const { t } = useTranslation();

  const handleChange = (field) => (value) => {
    setIntegration((prevIntegration) => ({
      ...prevIntegration,
      interval: {
        ...prevIntegration?.interval,
        [field]: value,
      },
    }));
  };

  const getOptions = (field) => {
    const max = field === "days" ? 101 : field === "hours" ? 24 : 60;
    return [...Array(max).keys()].map((value) => (
      <Option key={value} value={value}>
        {value}
      </Option>
    ));
  };

  return (
    <div className="w-2/5 space-y-1">
      <div className="text-gray-700 text-lg mt-5">Interval Scheduler</div>
      <div className="grid gap-2 text-xs grid-cols-3">
        {["days", "hours", "minutes"].map((field) => (
          <div key={field} className="flex flex-col">
            <label htmlFor={field}>{t(`form_labels.${field}`)}</label>
            <Select
              className="w-full"
              value={integration?.interval?.[field]}
              onChange={handleChange(field)}
            >
              {getOptions(field)}
            </Select>
          </div>
        ))}
      </div>
    </div>
  );
};

export default IntervalScheduler;
